(function($) {
    "use strict";

    var currentDoc = $(document),
        currentWindow = $(window),
        md = new MobileDetect(window.navigator.userAgent);


    currentDoc.ready(function() {

    	// Hyphens für Chrome
    	var htmlLang = $('html').attr('lang');
    	if (htmlLang == 'EN') {
    		htmlLang = 'en-us';
    	}
    	$('.hypenate').hyphenate(htmlLang);


    	$(".navbar-toggler").on('click touch', function () {

	    	if ($(".navbar-toggler").hasClass("collapsed")) {
			    $('.navbar-brand').addClass('small');
			} else {
			    $('.navbar-brand').removeClass('small');
			}

    	});

    	$('.lang-switch-current').on('click touch', function (e) {
    	    e.preventDefault();
    	    $('.lang-switch').toggleClass('open');
    	})

    	if ($('.welcome-slider').length > 0) {
    		var welcomeSlider = $('.welcome-slider');

			welcomeSlider.on('init', function(event, slick){
			    $('.loader-wrap, .sk-cube-grid').delay(1000).fadeOut('slow');
			});

	    	welcomeSlider.slick({
				dots: true,
				infinte: true,
				vertical: true,
				arrows: false,
				verticalSwiping: true,
			    slidesToShow: 1,
			    slidesToScroll: 1,
			});

			welcomeSlider.on('wheel', (function(e) {
				e.preventDefault();

				if (e.originalEvent.deltaY < 0) {
					$(this).slick('slickPrev');
				} else {
					$(this).slick('slickNext');
				}

			}));
    	}

    	if ($('.content-slider').length > 0) {
    		var contentSlider = $('.content-slider');

	    	contentSlider.slick({
				dots: true,
				infinte: true,
			    slidesToShow: 1,
			    slidesToScroll: 1,
			    adaptiveHeight: true
			});

    	}

    	if ( $('body').hasClass('mobile') ) {
    		console.log('hi');

    	}

   //  	if ( md.mobile() == null ) {
	  //   	$('.facts-wrap').hover(function () {
			// 	$(this).children('.collapse').collapse('show');
			// }, function() {
			// 	$(this).children('.collapse').collapse('hide');
			// });
   //  	}

    });


})(jQuery);